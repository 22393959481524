export const DEFAULT_LANG = 'ua';
export const SUPPORTED_LANGS = ['ua', 'en'];

export const BACKEND_URL = "http://localhost:3030";

export enum LANGUAGES {
	UA = 'ua',
	EN = 'en',
}

export const config = {
	price: {
		courseFpv: {
			military: "9 800",
			civil: "15 000"
		},
		courseMavic: {
			military: "6 000",
			civil: "10 000"
		},
		courseEngineer: {
			military: "8 500",
			civil: "12 000"
		},
		courseShulika: {
			military: "6 800",
			civil: "11 000"
		}
	},
	duration: {
		courseFpv: {
			days: "10",
			hrs: "80+"
		},
		courseMavic: {
			days: "5",
			hrs: "35+"
		},
		courseEngineer: {
			days: "5",
			hrs: "35+"
		},
		courseShulika: {
			days: "5",
			hrs: "35"
		}
	},
	contactUs: {
		fbLink: "https://www.facebook.com/dragonskyua",
		instagramLink: "https://www.instagram.com/dragon_sky_ua/",
		googleForm: "https://docs.google.com/forms/d/e/1FAIpQLSey0sMXsi_RA7VfwHuTlDUZbMwETwB_TOUEM6aijdgS6E0-CA/viewform",
		youtubeLink: "https://www.youtube.com/@DragonSkyUA"
	},
	courseName: {
		courseFpv: "",
		courseMavic: {
			military: "5 800",
			civil: "10 000"
		},
		courseEngineer: "FPV інженерія в бойових умовах",
		courseShulika: {
			military: "6 800",
			civil: "11 000"
		}
	}
}
