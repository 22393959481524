import '../App.scss';
import Container from 'react-bootstrap/Container';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { config } from "../constants.ts";
import ContactForm from "../Components/ContactForm";

function Contact() {
	const { t } = useTranslation();
	const { fbLink:facebook, instagramLink:instagram, googleForm:google  } = config.contactUs;
	return (
		<>
			<Helmet>
				<title>{t('contactPage.headTitle')} ■ {t('global.siteDescription')}</title>
				<meta name="description" content={t('contactPage.headDesc')} />
			</Helmet>
			<div className="contact-hero">
				<Container>
					<h1>
						<small>{t('contactPage.title')}</small>
					</h1>
				</Container>
			</div>
			<div>
				<Container className="contact-content">
					<Row>
						<Col sm={5}>
							<div className="lead">
								<p>{t('contactPage.description')}</p>
								{/*<ContactForm/>*/}
							</div>
						</Col>
						<Col sm={7}>
							<h4>{t('contactPage.addressTitle')}</h4>
							<p>{t('contactPage.addressBody')}</p>

							<h4>{t('contactPage.socTitle')}</h4>
							<p><a href={facebook}>Facebook</a></p>
							<p><a href={instagram}>Instagram</a></p>

							<h4>{t('contactPage.formTitle')}</h4>
							<p><a href={google}>Форма зв'язку</a></p>



							{/*<h4>{t('contactPage.phoneTitle')}</h4>*/}
							{/*<p>*/}
							{/*	<a href="tel:+380000000000">+38 (000) 000-00-00</a>*/}
							{/*</p>*/}
							{/*<h4>Email</h4>*/}
							{/*<p>*/}
							{/*	<a href="mailto:info@dragonsky.com.ua">info@dragonsky.com.ua</a>*/}
							{/*</p>*/}
						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
}

export default Contact;
